import { defineStore, acceptHMRUpdate } from 'pinia'
import COOKIES_DEVICE from './cookies-device'

export const useGameStore = defineStore('gameStore', {
  state: () => ({
    gameItem: {},
    provider: null,
    providerGames: [],
    providerCasino: [],
    lotteryGameSubmenu: [],
    tableGameSubmenu: [],
    jackpotGameSubmenu: [],
    gameLists: [],
    resultProvider: null,
    currentRoute: null,
    placeholderCountLobby: 0
  }),
  actions: {
    mutate(payload: any) {
      // @ts-ignore
      this.$state[payload.property] = payload.with
    },
    setProvider(payload: any) {
      this.provider = payload
    },
    setProviderGames(payload: any) {
      this.providerGames = payload
    },
    setProviderCasino(payload:any) {
      this.providerCasino = payload
    },
    setCurrentRoute(payload: any) {
      this.currentRoute = payload
    },
    setGameItem(payload: any) {
      this.gameItem = payload
    },
    setGameLists(payload: any) {
      this.gameLists = payload
    },
    setPlaceholderCount(payload: any) {
      this.placeholderCountLobby = payload
    },
    setResultProvider(payload: any) {
      this.resultProvider = payload
    },
    setLotteryGameSubmenu(payload: any) {
      this.lotteryGameSubmenu = payload
    },
    setTableGameSubmenu(payload: any) {
      this.tableGameSubmenu = payload
    },
    setJackpotGameSubmenu(payload: any) {
      this.jackpotGameSubmenu = payload
    }
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGameStore, import.meta.hot))
}
